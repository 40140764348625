import {
  API,
  API_MEMBER,
  API_MEMBER_ME,
  setAuthToken,
  URL_API_MEMBER,
} from "../../../config/API.js";
import history from "../../../history.js";
import apiAuthService from "../../services/apiAuthService";

// CATEGORY
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_DETAIL_CATEGORY = "GET_DETAIL_CATEGORY";
export const GET_GAMES_VOUCHER = "GET_GAMES_VOUCHER";
export const GET_ALL_GAMES_VOUCHER = "GET_ALL_GAMES_VOUCHER";

// CATEGORY
export const getAllCategories = () => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get("/category");
};

export const getListGameItems = () => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get("/games/item/all");
};

export const getProductList = () => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get("/game_voucher/voucher/list");
};

export const getAllGamesVoucher = () => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get("/game_voucher");
};

export const getGameItems = (id) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get(`/games_item/${id}/products`);
};

export const getDetailCategory = (id) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get("/category/" + id);
};

export const addCategory = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.post("/category", params).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editCategory = (data) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.patch("/category/" + data.category_code, {
      category_name: data.category_name,
    }).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};

export const getGamesVoucher = (params) => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/game_voucher/` + params)
      .then((res) => {
        dispatch({
          type: GET_GAMES_VOUCHER,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_GAMES_VOUCHER,
          payload: [],
        });
      });
  };
};

// export const getAllGamesVoucher = (params) => {
//   return (dispatch) => {
//     const token = localStorage.getItem("jwt_token");
//     setAuthToken(token);
//     API.get(`/game_voucher` + params)
//       .then((res) => {
//         dispatch({
//           type: GET_ALL_GAMES_VOUCHER,
//           payload: res.data.data || [],
//         });
//       })
//       .catch(() => {
//         dispatch({
//           type: GET_ALL_GAMES_VOUCHER,
//           payload: [],
//         });
//       });
//   };
// };

// UNUSED
// export const addGamesVoucher = (params, id) => {
//   return async (dispatch) => {
//     const token = await localStorage.getItem("jwt_token");
//     setAuthToken(token);
//     API.defaults.headers.common["Content-Type"] = "multipart/form-data";
//     const res = await API.post(`/game_voucher/${id}`, params).catch((err) => {
//       return Promise.reject(err);
//     });

//     return res.data;
//   };
// };

export const addVoucherGames = async(params) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    return await API.post(`/game_voucher/add`, params);
};


export const importVoucherGames = async (params) => {
  const token = await localStorage.getItem("jwt_token");
  setAuthToken(token);
  API.defaults.headers.common["Content-Type"] = "multipart/form-data";
  return await API.post(`/game_voucher/import/voucher`, params);
};

export const detailVoucherGames = async (id) => {
  const token = await localStorage.getItem("jwt_token");
  setAuthToken(token);
  API.defaults.headers.common["Content-Type"] = "multipart/form-data";
  return await API.get(`/game_voucher/detail/${id}`);
};

export const updateVoucherGames = async (params) => {
  const token = await localStorage.getItem("jwt_token");
  setAuthToken(token);
  API.defaults.headers.common["Content-Type"] = "multipart/form-data";
  return await API.post(`/game_voucher/update`, params);
};

export const deleteVoucherGames = async (params) => {
  const token = await localStorage.getItem("jwt_token");
  setAuthToken(token);
  API.defaults.headers.common["Content-Type"] = "multipart/form-data";
  return await API.post(`/game_voucher/delete`, params);
};

export const editGamesVoucher = (params, id) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const res = await API.patch(`/game_voucher/${id}`, params).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const delCategory = (id) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.delete("/category/" + id);
};

// GAMES LIST
export const getAllGamesList = (params) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get(`/games${params}`);
};

export const getAllGames = () => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get(`/games/voucher/list`);
};

export const getDetailGamesList = (id) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get("/games/" + id);
};

export const addGamesList = (params) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  API.defaults.headers.common["Content-Type"] = "multipart/form-data";
  return API.post("/games", params);
};

export const editGamesList = (params) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  API.defaults.headers.common["Content-Type"] = "multipart/form-data";
  return API.patch("/games/" + params.id, params);
};

export const delGamesList = (id) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.delete("/games/" + id);
};

// GAMES LIST
export const getProductVoucher = () => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.get(`/game_voucher/voucher/list`);
};

export const updateBulkStatusVoucher = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/game_voucher/edit_status/bulk", body);
};

export const deleteBulkVoucher = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/game_voucher/delete/bulk", body);
};

export const changeOrderCategory = (params) => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.post('/category/change/reorder', params);
};

export const syncGameUnipin = () => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API_MEMBER.get(`/unipin/game/latest`);
};

export const syncGameItemUnipin = (game_code) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API_MEMBER.post(`/unipin/game_item/latest`, {game_code: game_code});
};

export const saveSyncGameUnipin = (params) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API_MEMBER.post(`/unipin/game/sync/save`, params);
};

export const saveSyncGameItemUnipin = (params) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API_MEMBER.post(`/unipin/game_item/sync/save`, params);
};

export const syncGameLapakGaming = () => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API_MEMBER.get(`/lg/game/latest`);
};

export const syncGameItemLapakGaming = (game_code) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API_MEMBER.post(`/lg/game_item/latest`, {category_code: game_code});
};

export const saveSyncGameLapakGaming = (params) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API_MEMBER.post(`/lg/game/sync/save`, params);
};

export const saveSyncGameItemLapakGaming = (params) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API_MEMBER.post(`/lg/game_item/sync/save`, params);
};

export const changeStatusItemLapakGaming = (params) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API_MEMBER.post(`/lg/game_item/status/change`, params);
};