import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { saveSyncGameLapakGaming } from "../../../redux/actions/GamesActions";
import GeneralButton from "../../buttons/GeneralButton";
import ModalSyncProductItemLG from "./ModalSyncProductItemLG";

const ModalSyncProductLG = ({ handleClose, open, data = [] }) => {
  const [submit, setSubmit] = useState(false);
  const [itemOpen, setItemOpen] = useState(false);
  const [dataGame, setDataGame] = useState(data);
  const [detailGame, setDetailGame] = useState({});
  const [loadingInstall, setLoadingInstall] = useState(false);

  useEffect(() => {
    //
  }, []);

  useEffect(() => {
    setDataGame(data);
  }, [data]);

  const setModalItemOpen = (index, item) => {
    setLoadingInstall(true);
    setDetailGame(item);

    if (!item.isInstalled) {
      saveSyncGameLapakGaming(item)
        .then((res) => {
          console.log(res);
          setDataRequired(index, item);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setDataRequired(index);
    }
  };

  const setDataRequired = (index, item) => {
    var newDataGame = dataGame;
    newDataGame[index].isInstalled = true;

    setDataGame([...newDataGame]);

    setItemOpen(true);
  };

  const handleCloseitem = (event, reason) => {
    if (reason == "backdropClick" || reason == "escapeKeyDown") return;

    setDetailGame({});
    setItemOpen(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth="lg" fullWidth>
        <DialogTitle>Synchronize Game Lapak Gaming</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Table
                className="buku-kas-table"
                style={{
                  borderTop: "1px #e6e5e5 solid",
                  marginTop: "20px",
                }}
              >
                <TableHead>
                  <TableRow style={{ borderBottom: "1px #e6e5e5 solid" }}>
                    <TableCell
                      colSpan={1}
                      className="font-medium text-15"
                      style={{ color: "#0a0a0a" }}
                      align="center"
                    >
                      No
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      className="font-medium text-15"
                      style={{ color: "#0a0a0a" }}
                    >
                      Title game
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      className="font-medium text-15"
                      style={{ color: "#0a0a0a" }}
                      align="center"
                    >
                      Aksi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataGame.map((item, index) => (
                    <TableRow hover key={index}>
                      <TableCell
                        className="text-14 pl-3"
                        align="center"
                        style={{ color: "#0A0A0A" }}
                        colSpan={1}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        className="text-14"
                        style={{ color: "#0A0A0A" }}
                        colSpan={3}
                      >
                        <div
                          className=" z-100 text-14 d-flex items-center"
                          style={{ gap: "16px" }}
                        >
                          {item.isInstalled ? (
                            <Avatar
                              variant="square"
                              src={item.icon_url}
                              width={"50px"}
                              style={{ borderRadius: "5px" }}
                            />
                          ) : (
                            <Badge badgeContent={"new"} color="error">
                              <Avatar
                                variant="square"
                                src={item.icon_url}
                                width={"50px"}
                                style={{ borderRadius: "5px" }}
                              />
                            </Badge>
                          )}
                          <div>
                            <p className="m-0">{item.name}</p>
                            <small>
                              {item.variant + " - " + item.check_id}
                            </small>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="text-14 pl-3"
                        align="center"
                        style={{ color: "#0A0A0A" }}
                        colSpan={3}
                      >
                        {!item.isInstalled ? (
                          <GeneralButton
                            color="primary"
                            name={
                              loadingInstall && detailGame.code == item.code
                                ? "Loading..."
                                : "Save"
                            }
                            data={() => setModalItemOpen(index, item)}
                            disabled={
                              loadingInstall && detailGame.code == item.code
                            }
                          />
                        ) : (
                          <GeneralButton
                            color="success"
                            name="Sync"
                            data={() => setModalItemOpen(index, item)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {dataGame.length <= 0 && (
                    <TableRow hover>
                      <TableCell
                        className="text-14 pl-3"
                        align="center"
                        style={{ color: "#0A0A0A" }}
                        colSpan={7}
                      >
                        Game sudah up to date
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className="flex-column-reverse flex-md-row">
            <Button
              color="default"
              className="w-full radius-6 text-capitalize py-2"
              disableElevation
              disabled={submit}
              onClick={handleClose}
              variant="outlined"
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <ModalSyncProductItemLG
        handleClose={handleCloseitem}
        open={itemOpen}
        handleSave={console.log("abc")}
        data={""}
        detailGame={detailGame}
      />
    </>
  );
};

export default ModalSyncProductLG;
