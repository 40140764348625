import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Icon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { useState, useEffect } from "react";

export default function ListItemProvider({
  handleChange = "",
  label = "",
  width,
  search = "",
  value = "",
  names = ""
}) {
  // for filter
  const [provider, setProvider] = useState([
    { name: "Unipin", code: "unipin" },
    { name: "Apigames", code: "apigames" },
    { name: "Digiflazz", code: "digiflazz" },
    { name: "Voucher", code: "voucher" },
    { name: "LapakGaming", code: "lapakgaming" },
  ]);

  useEffect(() => {
    // 
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        backgroundColor: search ? "#ffffff" : "#fbfbfb ",
        height: search ? "38px" : "42px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: search ? null : "#e6e9ed",
        borderWidth: search ? null : "1px",
      },
      "& .MuiSelect-select": {
        fontWeight: "400",
        fontSize: "14px",
        color: "#0A0A0A",
      },
      "& .MuiInputLabel-outlined": {
        color: "#9196ab",
        fontSize: search ? null : "14px",
        top: search ? "-7px" : "10px",
        left: search ? null : "14px",
        transform: search ? null : "translate(0, 0) scale(1)",
        '&[data-shrink="true"]': {
          top: search ? "null" : "-6px",
          fontSize: search ? null : "12px",
          fontWeight: search ? null : "400",
          color: "#6B7280",
          transform: search ? null : "translate(0, 0) scale(1)",
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="w-full">
      <FormControl
        variant="outlined"
        className={classes.root}
        InputLabelProps={{ classes: { root: classes.inputLabel } }}
        style={{ width: width }}
      >
        <InputLabel shrink={false}>{value ? null : label}</InputLabel>

        <Select
          value={value}
          onChange={handleChange}
          className=" w-full"
          IconComponent={KeyboardArrowDownIcon}
          variant="outlined"
          name={names}
        >
          {search && (
            <MenuItem key="semua" value="">
              Lihat Semua
            </MenuItem>
          )}
          {provider.map((item, index) => (
            <MenuItem key={index} text={item.name} value={item.code}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
