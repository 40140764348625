import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React, { useEffect } from "react";

const ModalSyncSelect = ({ handleClose, open, handleUnipin, handleLapakGaming, loading }) => {
  useEffect(() => {
    //
  }, []);

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          <h3 className="w-full flex justify-between">
            Pilih Provider/Asal Produk
            <p onClick={handleClose} className="cursor-pointer" disabled={loading}>&#x2715;</p>
          </h3>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Button
                color="primary"
                className="w-full radius-6 text-capitalize py-2 text-white"
                disableElevation
                onClick={handleUnipin}
                variant="contained"
                disabled={loading}
              >
                Unipin
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                className="w-full radius-6 text-capitalize py-2 text-white"
                disableElevation
                onClick={handleLapakGaming}
                variant="contained"
                disabled={loading}
              >
                LapakGaming
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className="flex-column-reverse flex-md-row">{/*  */}</div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalSyncSelect;
