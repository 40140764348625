import {
  Card,
  Grid,
  Icon,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../../../styles/css/DetailUser.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { UploadImage } from "../../components";
import { addGamesList } from "../../redux/actions/GamesActions";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import GeneralButton from "../../components/buttons/GeneralButton.jsx";
import SelectOfArray from "../../components/select/SelectOfArray";
import ListItemGameFilter from "./components/ListItemGameFilter";
import ListItemProvider from "./components/ListItemProvider.jsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1253FA",
    },
  },
});

const AddGamesListGames = () => {
  const history = useHistory();

  const [onHoverFieldInfo, setOnHoverFieldInfo] = useState(false);

  useEffect(() => {
    if (onHoverFieldInfo) {
      document.body.style.cursor = "pointer";
    } else {
      document.body.style.cursor = "default";
    }
  }, [onHoverFieldInfo]);

  const handleOnHoverFieldInfo = () => {
    setOnHoverFieldInfo(true);
  };

  const handleOnLeaveFieldInfo = () => {
    setOnHoverFieldInfo(false);
  };

  const [state, setState] = useState({
    foto: "",
    foto2: "",
    title: "",
    kode_game: "",
    game_description: "",
    field_description: "",
    field_img: "",
    from: "",
    lg_variant: "",
    check_id: "",
    meta_desc: "",
    meta_title: "",
    meta_keyword: "",
  });

  const [category, setCategory] = useState("");

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    try {
      switch (state.from) {
        case "voucher":
          if (
            !state.foto ||
            !state.title ||
            !state.game_description ||
            !category
          ) {
            throw new Error("Data Game Belum lengkap");
          }

          productList.forEach((obj, index) => {
            console.log(obj);
            if (
              !obj.nama ||
              !obj.status_produk ||
              !obj.harga_member ||
              !obj.harga_non_member ||
              !obj.harga_reseller
            ) {
              throw new Error(
                "Isian Product " + (index + 1) + " belum lengkap"
              );
            }
          });
          break;
        case "unipin":
          if (
            !state.foto ||
            !state.foto2 ||
            !state.title ||
            !state.kode_game ||
            !state.game_description ||
            !state.field_description ||
            !category
          ) {
            throw new Error("Data Game belum lengkap");
          }

          fieldList.forEach((obj) => {
            if (!obj.nama || !obj.tipe) {
              throw new Error("Kolom Fields belum lengkap");
            }
          });

          productList.forEach((obj, index) => {
            console.log(obj);
            if (
              !obj.nama ||
              !obj.status_produk ||
              !obj.harga_member ||
              !obj.harga_non_member ||
              !obj.harga_reseller ||
              !obj.denomination_id
            ) {
              throw new Error(
                "Isian Product " + (index + 1) + " belum lengkap"
              );
            }
          });
          break;
        case "apigames":
          if (
            !state.foto ||
            !state.foto2 ||
            !state.title ||
            !state.kode_game ||
            !state.game_description ||
            !state.field_description ||
            !category
          ) {
            throw new Error("Data Game belum lengkap");
          }

          fieldList.forEach((obj) => {
            if (!obj.nama || !obj.tipe) {
              throw new Error("Kolom Fields belum lengkap");
            }
          });

          productList.forEach((obj, index) => {
            console.log(obj);
            if (
              !obj.nama ||
              !obj.status_produk ||
              !obj.harga_member ||
              !obj.harga_non_member ||
              !obj.harga_reseller ||
              !obj.ag_code
            ) {
              throw new Error(
                "Isian Product " + (index + 1) + " belum lengkap"
              );
            }
          });
          break;
        case "digiflazz":
          if (
            !state.foto ||
            !state.foto2 ||
            !state.title ||
            !state.kode_game ||
            !state.game_description ||
            !state.field_description ||
            !category
          ) {
            throw new Error("Data Game belum lengkap");
          }

          fieldList.forEach((obj) => {
            if (!obj.nama || !obj.tipe) {
              throw new Error("Kolom Fields belum lengkap");
            }
          });

          productList.forEach((obj, index) => {
            console.log(obj);
            if (
              !obj.nama ||
              !obj.status_produk ||
              !obj.harga_member ||
              !obj.harga_non_member ||
              !obj.harga_reseller ||
              !obj.digi_code
            ) {
              throw new Error(
                "Isian Product " + (index + 1) + " belum lengkap"
              );
            }
          });
          break;
        case "lapakgaming":
          if (
            !state.foto ||
            (!state.foto2 && state.lg_variant == "DIGITAL") ||
            !state.title ||
            !state.kode_game ||
            !state.game_description ||
            (!state.field_description && state.lg_variant == "DIGITAL") ||
            !category
          ) {
            throw new Error("Data Game belum lengkap");
          }

          productList.forEach((obj, index) => {
            if (
              !obj.nama ||
              !obj.status_produk ||
              !obj.harga_member ||
              !obj.harga_non_member ||
              !obj.harga_reseller ||
              !obj.lg_code
            ) {
              throw new Error(
                "Isian Product " + (index + 1) + " belum lengkap"
              );
            }
          });
          break;

        default:
          break;
      }

      addGamesList({
        from: state.from,
        img: state.foto,
        field_img: state.foto2,
        title: state.title,
        category_code: category,
        kode_game: state.kode_game,
        game_description: state.game_description,
        field_description: state.field_description,
        lg_variant: state.lg_variant,
        check_id: state.check_id,
        check_id: state.check_id,
        meta_desc: state.meta_desc,
        meta_title: state.meta_title,
        meta_keyword: state.meta_keyword,
        fieldList,
        productList,
      })
        .then((res) => {
          if (res.code == 2 || res.code == 1) {
            throw new Error(res.message);
          }

          let timerInterval;
          Swal.fire({
            title: "Sedang diproses...",
            html: "tunggu dalam waktu <b></b> detik.",
            timer: 4000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              setTimeout(() => {
                clearInterval(timerInterval);
                history.push("/games/listGames");
                Swal.fire(
                  "Success!",
                  "List Games berhasil disimpan",
                  "success"
                );
              }, 4000);
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 1000);
            },
          });
        })
        .catch((e) => {
          Swal.fire("Oopss!", e.message, "error");
        });
    } catch (e) {
      Swal.fire("Oopss!", e.message, "error");
    }
  };

  // FIELDS
  const [fieldList, setFieldList] = useState([
    {
      nama: "",
      tipe: "",
    },
  ]);

  const renderFields = () => {
    const isLastInput = (index) => fieldList.length - 1 !== index;
    const onlyOne = () => fieldList.length == 1;

    return (
      <>
        {fieldList?.map((item, index) => (
          <Grid
            key={index}
            container
            justifyContent="space-between"
            spacing={1}
            style={{
              marginBottom: "30px",
            }}
          >
            <Grid item sm={state.from == 'lapakgaming' && state.check_id == 'active' ? 3 : 4}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Display Name
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={fieldList[index].display_name}
                name="display_name"
                placeholder="Display Name"
                variant="outlined"
                onChange={handleFieldChange(index, "display_name")}
              />
            </Grid>
            <Grid item sm={3}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Code
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                value={fieldList[index].nama}
                name="nama"
                placeholder="Code"
                className="w-full"
                variant="outlined"
                onChange={handleFieldChange(index, "nama")}
              />
            </Grid>
            {
              state.from == 'lapakgaming' && state.check_id == 'active' ?
              <Grid item sm={3}>
                <h1
                  className="mb-5 font-semimedium text-14"
                  style={{ color: "#0a0a0a" }}
                >
                  Code Checkid
                </h1>
                <TextField
                  required={true}
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: 5,
                      minHeight: 46,
                    },
                  }}
                  className="w-full"
                  value={fieldList[index].name_other}
                  name="name_other"
                  placeholder="Code Checkid"
                  variant="outlined"
                  onChange={handleFieldChange(index, "name_other")}
                />
              </Grid>
              : ""
            }
            <Grid item sm={state.from == 'lapakgaming' && state.check_id == 'active' ? 2 : 3}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Tipe
              </h1>
              <SelectOfArray
                dataSelect={[
                  {
                    text: "String",
                    value: "string",
                  },
                  {
                    text: "Number",
                    value: "number",
                  },
                  {
                    text: "Dropdown",
                    value: "dropdown",
                  },
                ]}
                state={fieldList}
                setState={setFieldList}
                size="small"
                label="Tipe"
                width="100%"
                name="tipe"
                index={index}
                menuItemFontSize="text-14"
              />
            </Grid>

            {onlyOne() ? (
              <Grid item className="mt-10" sm={state.from == 'lapakgaming' && state.check_id == 'active' ? 1 : 2}>
                <div
                  className="border-radius-circle bg-primary text-white w-35 h-35"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleAddField}
                >
                  <Icon fontSize="medium">add-icon</Icon>
                </div>
              </Grid>
            ) : isLastInput(index) ? (
              <Grid item className="mt-10" sm={state.from == 'lapakgaming' && state.check_id == 'active' ? 1 : 2}>
                <div
                  className="border-radius-circle bg-error w-35 h-35"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleRemoveField(index)}
                >
                  <Icon fontSize="medium">delete-outline-icon</Icon>
                </div>
              </Grid>
            ) : (
              <Grid
                item
                className="mt-10"
                sm={state.from == 'lapakgaming' && state.check_id == 'active' ? 1 : 2}
                container
                justifyContent="start"
              >
                <Grid item className="mr-2">
                  <div
                    className="border-radius-circle bg-primary w-35 h-35 text-white"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleAddField}
                  >
                    <Icon fontSize="medium">add-icon</Icon>
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className="border-radius-circle bg-error w-35 h-35"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handleRemoveField(index)}
                  >
                    <Icon fontSize="medium">delete-outline-icon</Icon>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </>
    );
  };

  const renderSEO = () => {
    return (
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        style={{
          marginBottom: "30px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <h1
            className={"mt-2 font-semimedium text-14 mb-1"}
            style={{ color: "#0a0a0a" }}
          >
            Meta Title
          </h1>
          <TextField
            required={true}
            size="small"
            multiline
            minRows={5}
            InputProps={{
              style: {
                borderRadius: 5,
                minHeight: 30,
              },
            }}
            className="w-full mt-2"
            value={state.meta_title}
            name="meta_title"
            placeholder="Meta Title"
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h1
            className={"mt-2 font-semimedium text-14 mb-1"}
            style={{ color: "#0a0a0a" }}
          >
            Meta Deskripsi
          </h1>
          <TextField
            required={true}
            size="small"
            multiline
            minRows={5}
            InputProps={{
              style: {
                borderRadius: 5,
                minHeight: 30,
              },
            }}
            className="w-full mt-2"
            value={state.meta_desc}
            name="meta_desc"
            placeholder="Meta Desckripsi"
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h1
            className={"mt-2 font-semimedium text-14 mb-1"}
            style={{ color: "#0a0a0a" }}
          >
            Meta Keyword
          </h1>
          <TextField
            required={true}
            size="small"
            multiline
            minRows={5}
            InputProps={{
              style: {
                borderRadius: 5,
                minHeight: 30,
              },
            }}
            className="w-full mt-2"
            value={state.meta_keyword}
            name="meta_keyword"
            placeholder="Meta Keyword"
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    );
  };

  const handleFieldChange = (index, name) => (e) => {
    const updatedField = [...fieldList];

    updatedField[index][name] = e.target.value;

    setFieldList(updatedField);
  };

  const handleAddField = () => {
    setFieldList((prev) => [
      ...prev,
      {
        nama: "",
        tipe: "",
      },
    ]);
  };

  const handleRemoveField = (index) => {
    const updatedField = [...fieldList];
    updatedField.splice(index, 1);
    setFieldList(updatedField);
  };

  // PRODUK
  const [productList, setProductList] = useState([
    {
      nama: "",
      status_produk: "",
      asal_produk: "",
      denomination_id: "",
      ag_code: "",
      digi_code: "",
      harga_member: "",
      harga_non_member: "",
      harga_reseller: "",
      lg_code: "",
      price_original: "",
    },
  ]);

  const renderProduct = () => {
    const isLastInput = (index) => productList.length - 1 !== index;
    const onlyOne = () => productList.length == 1;

    return (
      <>
        {productList?.map((item, index) => (
          <Grid
            key={index}
            container
            justifyContent="space-between"
            spacing={2}
            style={{
              marginBottom: "100px",
            }}
          >
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                <h3
                  className="text-20 font-medium mb-5"
                  style={{ color: "#0A0A0A" }}
                >
                  Product {index + 1}
                </h3>
              </Grid>
              {onlyOne() ? (
                <Grid item className="mt-5">
                  <Button
                    variant="contained"
                    className="bg-primary text-white border-radius-4 p-2"
                    onClick={() => handleAddProduct()}
                    fullWidth
                  >
                    <Icon className="mr-1" fontSize="medium">
                      add-icon
                    </Icon>
                    Add Product
                  </Button>
                </Grid>
              ) : isLastInput(index) ? (
                <Grid item className="mt-5">
                  <Button
                    variant="outlined"
                    onClick={() => handleRemoveProduct(index)}
                    fullWidth
                    className="border-error border-radius-4 p-2 text-error"
                  >
                    <Icon className="mr-1" fontSize="medium">
                      delete-outline-icon
                    </Icon>
                    Delete
                  </Button>
                </Grid>
              ) : (
                <Grid item container justifyContent="flex-end" spacing={2}>
                  <Grid item className="mt-5">
                    <Button
                      variant="contained"
                      className="bg-primary text-white border-radius-4 p-2"
                      onClick={() => handleAddProduct()}
                      fullWidth
                    >
                      <Icon className="mr-1" fontSize="medium">
                        add-icon
                      </Icon>
                      Add Product
                    </Button>
                  </Grid>
                  <Grid item className="mt-5">
                    <Button
                      variant="outlined"
                      onClick={() => handleRemoveProduct(index)}
                      fullWidth
                      className="border-error border-radius-4 p-2 text-error"
                    >
                      <Icon className="mr-1" fontSize="medium">
                        delete-outline-icon
                      </Icon>
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Nama
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                value={productList[index].nama}
                name="nama"
                placeholder="Nama"
                className="w-full"
                variant="outlined"
                onChange={handleProductChange(index, "nama")}
              />
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Status Produk
              </h1>
              <SelectOfArray
                dataSelect={[
                  {
                    text: "Aktif",
                    value: "1",
                  },
                  {
                    text: "Tidak Aktif",
                    value: "0",
                  },
                ]}
                state={productList}
                setState={setProductList}
                size="small"
                label="Status Produk"
                width="100%"
                name="status_produk"
                index={index}
                menuItemFontSize="text-14"
              />
            </Grid>

            {state.from != "voucher" ? (
              <Grid item sm={6}>
                {state.from?.toLowerCase() === "unipin" ? (
                  <>
                    <h1
                      className="mb-5 font-semimedium text-14"
                      style={{ color: "#0a0a0a" }}
                    >
                      Denomination ID
                    </h1>
                    <TextField
                      required={true}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: 5,
                          minHeight: 46,
                        },
                      }}
                      value={productList[index].denomination_id}
                      name="denomination_id"
                      placeholder="Denomination ID"
                      className="w-full"
                      variant="outlined"
                      onChange={handleProductChange(index, "denomination_id")}
                    />
                  </>
                ) : state.from?.toLowerCase() === "apigames" ? (
                  <>
                    <h1
                      className="mb-5 font-semimedium text-14"
                      style={{ color: "#0a0a0a" }}
                    >
                      Apigames Code
                    </h1>
                    <TextField
                      required={true}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: 5,
                          minHeight: 46,
                        },
                      }}
                      value={productList[index].ag_code}
                      name="ag_code"
                      placeholder="Apigames Code"
                      className="w-full"
                      variant="outlined"
                      onChange={handleProductChange(index, "ag_code")}
                    />
                  </>
                ) : state.from?.toLowerCase() === "digiflazz" ? (
                  <>
                    <h1
                      className="mb-5 font-semimedium text-14"
                      style={{ color: "#0a0a0a" }}
                    >
                      Digiflazz Code
                    </h1>
                    <TextField
                      required={true}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: 5,
                          minHeight: 46,
                        },
                      }}
                      value={productList[index].digi_code}
                      defaultValue={productList[index].digi_code}
                      name="digi_code"
                      placeholder="Digiflazz Code"
                      className="w-full"
                      variant="outlined"
                      onChange={handleProductChange(index, "digi_code")}
                    />
                  </>
                ) : state.from?.toLowerCase() === "lapakgaming" ? (
                  <>
                    <h1
                      className="mb-5 font-semimedium text-14"
                      style={{ color: "#0a0a0a" }}
                    >
                      LapakGaming Code
                    </h1>
                    <TextField
                      required={true}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: 5,
                          minHeight: 46,
                        },
                      }}
                      value={productList[index].lg_code}
                      name="lg_code"
                      placeholder="LapakGaming Code"
                      className="w-full"
                      variant="outlined"
                      onChange={handleProductChange(index, "lg_code")}
                    />
                  </>
                ) : (
                  ""
                )}
              </Grid>
            ) : (
              ""
            )}

            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Harga Member
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                value={productList[index].harga_member}
                name="harga_member"
                placeholder="Harga Member"
                className="w-full"
                variant="outlined"
                onChange={handleProductChange(index, "harga_member")}
              />
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Harga Non Member
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                value={productList[index].harga_non_member}
                name="harga_non_member"
                placeholder="Harga Non Member"
                className="w-full"
                variant="outlined"
                onChange={handleProductChange(index, "harga_non_member")}
              />
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Harga Reseller
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                value={productList[index].harga_reseller}
                name="harga_reseller"
                placeholder="Harga Reseller"
                className="w-full"
                variant="outlined"
                onChange={handleProductChange(index, "harga_reseller")}
              />
            </Grid>
            <Grid item sm={6}>
              <h1
                className="mb-5 font-semimedium text-14"
                style={{ color: "#0a0a0a" }}
              >
                Harga Original
              </h1>
              <TextField
                required={true}
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 5,
                    minHeight: 46,
                  },
                }}
                className="w-full"
                value={productList[index].price_original}
                name="price_original"
                placeholder="Harga Original"
                variant="outlined"
                onChange={handleProductChange(index, "price_original")}
              />
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  const handleProductChange = (index, name) => (e) => {
    const updatedField = [...productList];

    updatedField[index][name] = e.target.value;

    setProductList(updatedField);
  };

  const handleAddProduct = () => {
    setProductList((prev) => [
      ...prev,
      {
        asal_produk: "",
        tipe: "",
      },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const updatedField = [...productList];
    updatedField.splice(index, 1);
    setProductList(updatedField);
  };

  const handleChangePhoto1 = (file, path) => {
    setState({
      ...state,
      foto: file,
      preview: path,
    });
  };

  const handleChangePhoto2 = (file, path) => {
    setState({
      ...state,
      foto2: file,
      preview2: path,
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#fbfbfb ",
        height: "42px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e6e9ed",
        borderWidth: "1px",
      },
      "& .MuiSelect-select": {
        fontWeight: "400",
        fontSize: "14px",
        color: "#0A0A0A",
      },
      "& .MuiInputLabel-outlined": {
        color: "#9196ab",
        fontSize: "14px",
        top: "10px",
        left: "14px",
        transform: "translate(0, 0) scale(1)",
        '&[data-shrink="true"]': {
          top: "-6px",
          fontSize: "12px",
          fontWeight: "400",
          color: "#6B7280",
          transform: "translate(0, 0) scale(1)",
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="analytics m-sm-30 mt-7 text-black">
      <h1 className="fw-600 m-0">Add Games</h1>
      <Grid
        item
        xs={12}
        sm
        className="d-flex mr-8"
        style={{ justifyContent: "flex-end" }}
      >
        <ThemeProvider theme={theme}>
          <GeneralButton variant="contained" name="Save" data={handleSubmit} />
        </ThemeProvider>
      </Grid>
      <Card className="mt-5 py-10 px-10">
        <div className="mx-8 mt-5 mb-8 flex flex-between gap-3">
          <Grid item xs={12} sm={6}>
            <h3
              className="text-20 font-medium mb-5"
              style={{ color: "#0A0A0A" }}
            >
              Provider/Asal Produk
            </h3>
            <ListItemProvider
              value={state.from}
              label="Asal Produk"
              names="from"
              handleChange={handleChange}
            />
          </Grid>
          {state.from == "lapakgaming" ? (
            <Grid item xs={12} sm={6}>
              <h1
                className="mt-2 font-semimedium text-14 mb-5"
                style={{ color: "#0a0a0a" }}
              >
                Varian
              </h1>

              <FormControl
                variant="outlined"
                className={classes.root}
                InputLabelProps={{ classes: { root: classes.inputLabel } }}
              >
                <Select
                  value={state.lg_variant}
                  onChange={handleChange}
                  className=" w-full"
                  IconComponent={KeyboardArrowDownIcon}
                  variant="outlined"
                  name="lg_variant"
                >
                  <MenuItem key={1} text="DIGITAL" value="DIGITAL">
                    DIGITAL
                  </MenuItem>
                  <MenuItem key={2} text="VOUCHER" value="VOUCHER">
                    VOUCHER
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : (
            ""
          )}
        </div>
      </Card>
      {state.from != "" ? (
        <>
          <Card className="mt-5 py-10 px-10">
            <div className="mx-8 mt-5 mb-8">
              <Grid item xs={12} sm={6}>
                <h3
                  className="text-20 font-medium mb-5"
                  style={{ color: "#0A0A0A" }}
                >
                  Games
                </h3>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h1
                  className="font-semimedium text-14"
                  style={{ color: "#0a0a0a" }}
                >
                  Unggah Foto
                </h1>
                <UploadImage
                  uploadFoto={handleChangePhoto1}
                  label="Banner"
                  preview={state.preview}
                  formatIcon={false}
                />
              </Grid>
              <Grid container className="mt-2" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <h1
                    className="mb-5 font-semimedium text-14"
                    style={{ color: "#0a0a0a" }}
                  >
                    Game
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                    value={state.title}
                    name="title"
                    placeholder="Games"
                    className="w-full"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h1
                    className="mb-5 font-semimedium text-14"
                    style={{ color: "#0a0a0a" }}
                  >
                    Kategori
                  </h1>
                  {/* <ListGamesFilter
                value={category}
                label="Kategori"
                name="category"
                handleChange={handleCategory}
              /> */}
                  <ListItemGameFilter
                    value={category}
                    label="Kategori"
                    name="category"
                    handleChange={handleCategory}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h1
                    className="mb-5 font-semimedium text-14"
                    style={{ color: "#0a0a0a" }}
                  >
                    Kode Game
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                    className="w-full"
                    value={state.kode_game}
                    name="kode_game"
                    placeholder="Kode Game"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                {(state.from != "voucher" && state.from != "lapakgaming") ||
                (state.lg_variant == "DIGITAL" &&
                  state.from == "lapakgaming") ? (
                  <Grid item xs={12} sm={6}>
                    <h1
                      className="mb-5 font-semimedium text-14"
                      style={{ color: "#0a0a0a" }}
                    >
                      Field Deskripsi
                    </h1>
                    <TextField
                      required={true}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: 5,
                          minHeight: 46,
                        },
                      }}
                      className="w-full"
                      value={state.field_description}
                      name="field_description"
                      placeholder="Field Deskripsi"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12} sm={6}>
                  <h1
                    className={
                      "mt-2 font-semimedium text-14" +
                      ((state.from != "voucher" &&
                        state.from != "lapakgaming") ||
                      (state.lg_variant == "DIGITAL" &&
                        state.from == "lapakgaming")
                        ? " mb-5"
                        : " mb-1")
                    }
                    style={{ color: "#0a0a0a" }}
                  >
                    Game Deskripsi
                  </h1>
                  <TextField
                    required={true}
                    size="small"
                    multiline
                    rows={10}
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                    className="w-full mt-2"
                    value={state.game_description}
                    name="game_description"
                    placeholder="Game Deskripsi"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                {(state.from != "voucher" && state.from != "lapakgaming") ||
                (state.lg_variant == "DIGITAL" &&
                  state.from == "lapakgaming") ? (
                  <Grid item xs={12} sm={6}>
                    <h1
                      className="font-semimedium text-14"
                      style={{ color: "#0a0a0a" }}
                    >
                      Foto
                      <span
                        className="mx-2 border-radius-circle bg-primary w-20 h-20 text-white"
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "1rem",
                          padding: "0 0 2px 0.5px",
                        }}
                        onMouseEnter={handleOnHoverFieldInfo}
                        onMouseLeave={handleOnLeaveFieldInfo}
                      >
                        <span onClick={handleAddField}>
                          <QuestionMarkIcon
                            style={{ fontSize: "15px", fontWeight: "700" }}
                          >
                            add-icon
                          </QuestionMarkIcon>
                        </span>
                      </span>
                      {onHoverFieldInfo ? (
                        <div className="img-helper">
                          <div
                            id="img-helper-element"
                            style={{
                              position: "absolute",
                              zIndex: "999",
                              left: "40%",
                            }}
                          >
                            <img
                              src="https://vogaon.com/wp-content/plugins/woo-unipin/unipin/assets/img/default-guide.png"
                              style={{ position: "relative", zIndex: "1000" }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </h1>

                    <UploadImage
                      uploadFoto={handleChangePhoto2}
                      label="Banner"
                      preview={state.preview2}
                      formatIcon={false}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {state.lg_variant == "DIGITAL" &&
                state.from == "lapakgaming" ? (
                  <Grid item xs={12} sm={6}>
                    <h1
                      className="mt-2 font-semimedium text-14 mb-5"
                      style={{ color: "#0a0a0a" }}
                    >
                      Check ID
                    </h1>

                    <Select
                      value={state.check_id}
                      onChange={handleChange}
                      className=" w-full"
                      IconComponent={KeyboardArrowDownIcon}
                      variant="outlined"
                      name="check_id"
                    >
                      <MenuItem key={1} text="active" value="active">
                        Yes
                      </MenuItem>
                      <MenuItem key={1} text="inactive" value="inactive">
                        No
                      </MenuItem>
                    </Select>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          </Card>

          {/* FIELDS */}
          {(state.from != "voucher" && state.from != "lapakgaming") ||
          (state.lg_variant == "DIGITAL" && state.from == "lapakgaming") ? (
            <Card className="mt-5 py-10 px-10">
              <div className="mx-8 mt-5 mb-8">
                <Grid item xs={12} sm={6}>
                  <h3
                    className="text-20 font-medium mb-5"
                    style={{ color: "#0A0A0A" }}
                  >
                    Fields
                  </h3>
                </Grid>
                {renderFields()}
              </div>
            </Card>
          ) : (
            ""
          )}

          <Card className="mt-5 py-10 px-10">
            <div className="mx-8 mt-5 mb-8">
              <Grid item xs={12} sm={6}>
                <h3
                  className="text-20 font-medium mb-5"
                  style={{ color: "#0A0A0A" }}
                >
                  SEO
                </h3>
              </Grid>
              {renderSEO()}
            </div>
          </Card>

          {/* PRODUK */}
          <Card className="mt-5 py-10 px-10">
            <div className="mx-8 mt-5 mb-8">{renderProduct()}</div>
            {/* <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <button
              className="mx-8 mb-8 border-radius-circle bg-primary text-white w-35 h-35"
              style={{ marginTop: "25px", padding: "8.7px" }}
              onClick={goToTop}
            >
              <Icon className="mr-1" fontSize="medium">
                <KeyboardArrowUpIcon />
              </Icon>
            </button>
          </Grid>
        </Grid> */}
          </Card>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default AddGamesListGames;
