import { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { useDispatch } from "react-redux";
import React from "react";
import {
  delCategory,
  deleteVoucherGames,
} from "../../../redux/actions/GamesActions";

export default function MenuCategory({ item, editPath, getData, type }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    getData();
    setAnchorEl(null);
  };

  const deleteCategory = (id) => {
    delCategory(id)
      .then((res) => {
        Swal.fire("Success!", "Data berhasil dihapus", "success");
        handleClose();
      })
      .catch((err) => {
        Swal.fire("Oopss!", "Data gagal dihapus");
      });
  };

  const deleteVoucher = () => {
    const data = {
      item_code: item.item_code,
    };
    deleteVoucherGames(data)
      .then((res) => {
        Swal.fire("Success!", "Data berhasil dihapus", "success");
        handleClose();
      }
      )
      .catch((err) => {
        Swal.fire("Oopss!", "Data gagal dihapus");
      });
  };

  const loadingModal = () => {
    Swal.fire({
      title: "Sedang diproses...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  const submitData = (id) => {
    switch (type) {
      case "voucher":
        deleteVoucher();
        break;
      case "category":
        deleteCategory(id);
        break;
      default:
        return null;
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        loadingModal();
        submitData(id);
         
           
         
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="elevation-z0"
      >
       
        <MoreHorizIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          to={{
            pathname: editPath,
          }}
        >
          <MenuItem className="mb-3" onClick={handleClose}>
            <span className="">
              <CreateOutlinedIcon style={{ color: "#1253FA" }} />
            </span>
            <span className="pl-3" style={{ color: "#1253FA" }}>
              Edit Data
            </span>
          </MenuItem>
        </Link>
        {/* {item.category_code !== "MG" && item.category_code !== "VG" && ( */}
        <MenuItem className="" onClick={() => handleDelete(item.category_code)}>
          <span className="text-red">
            <DeleteOutlineOutlinedIcon />
          </span>
          <span className="pl-3 text-red">Hapus Data</span>
        </MenuItem>
        {/* )} */}
      </Menu>
    </div>
  );
}
