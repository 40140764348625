import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Input,
} from "@material-ui/core";
import SimpleCard from "../../assets/components/cards/SimpleCard";
import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  getDetailPembelian,
  updateStatusPembelian,
  resendKodeVoucher,
  resenditem,
  resenditemLapakGaming,
} from "../../redux/actions/Transaction/TransactionActions";
import { formatRupiah } from "../../../utlis/formatRupiah";
import TableDetailListPembelian from "../../components/sections/TableDetailListPembelian";
import { da } from "date-fns/locale";
import { sum } from "lodash";
import SelectOfArray from "../../components/select/SelectOfArray";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import GeneralButton from "../../components/buttons/GeneralButton.jsx";
import ModalResendEmail from "./components/ModalResendEmail";

const DetailPembelian = ({ getDetailPembelian, detailPembelian }) => {
  const { id } = useParams();
  const getData = () => {
    getDetailPembelian(id);
  };

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    totalPembelian: 0,
  });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");

  const openEmailModal = () => {
    setShowEmailModal(true);
  };

  const closeEmailModal = () => {
    setShowEmailModal(false);
    setEmail("");
  };

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const setTotalPembelian = (totalPembelian) => {
    setState({ ...state, totalPembelian });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    setSelectedOption(detailPembelian?.status);
  }, [detailPembelian]);

  // updatePaymentMethod(state.pm_code, obj)
  // .then((res) => {
  //   getData();
  //   setState((prev) => ({
  //     ...prev,
  //     content: false,
  //   }));
  //   Swal.fire(
  //     "Berhasil!",
  //     "Data Payment Method berhasil disimpan",
  //     "success"
  //   );
  // })
  // .catch((err) => {
  //   Swal.fire("Gagal!", "System Under Maintenance !", "error");
  // });

  const handleSubmitUpdateStatus = () => {
    const body = {
      kode_transaksi: detailPembelian?.transaction_code,
      status: selectedOption,
    };
    updateStatusPembelian(body).then((res) => {
      const success = res.data.success;
      if (success) {
        getDetailPembelian(body.kode_transaksi);
        Swal.fire(
          "Berhasil!",
          "Status Pembelian berhasil diperbarui",
          "success"
        );
      } else {
        Swal.fire("Gagal", "Status Pembelian gagal diperbarui", "error");
      }
    });
  };

  const data = {
    order_id: detailPembelian?.transaction_code,
  };

  const handleResend = () => {
    if (detailPembelian.type === "order_voucher") {
      openEmailModal();
    }
    if (detailPembelian.type === "order_item") {
      Swal.fire({
        title: "Mohon tunggu...",
        showConfirmButton: false,
        willClose: () => {
          Swal.showLoading();
        },
      });
      const body = {
        order_id: detailPembelian?.transaction_code,
      };
      resenditem(body).then((res) => {
        // setSelectedOption("processing");
        const success = res.data.success;
        if (success) {
          Swal.fire(
            "Berhasil",
            "Resend item berhasil, Status Pembelian diperbarui!",
            "success"
          );
        } else {
          Swal.fire("Gagal", "Resend item gagal!", "error");
        }
      });
    }
  };

  const handleResendLapakGaming = () => {
    Swal.fire({
      icon: "question",
      title: "Resend order",
      text: "Resend order ini ke LapakGaming ?",
      showCancelButton: true,
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          transaction_code: detailPembelian?.transaction_code,
        };
        resenditemLapakGaming(body)
          .then((res) => {
            const success = res.data.success;
            if (success) {
              Swal.fire(
                "Berhasil",
                "Resend order berhasil, Status Pembelian akan diperbarui!",
                "success"
              );

              window.location.reload();
            } else {
              Swal.fire("Gagal", "Resend item gagal!", "error");
            }
          })
          .catch((err) => {
            let error = err?.response?.data;

            Swal.fire(
              "Gagal !",
              "Resend order gagal, karena " + error?.message,
              "error"
            );
          });
      }
    });
  };

  const tableHeadItems = [
    { name: "No", align: "center", colSpan: 1 },
    { name: "Nama Produk", align: "left", colSpan: 4 },
    { name: "Harga Satuan", align: "center", colSpan: 3 },
    { name: "Qty", align: "center", colSpan: 3 },
    { name: "Harga Total", align: "center", colSpan: 3 },
    // { name: "UserID", align: "center", colSpan: 3 },
    // { name: "Asal Produk", align: "center", colSpan: 3 },
    // { name: "Status Produk", align: "center", colSpan: 3 },
  ];

  const tableBodyItems = [
    { key: "games_title", align: "left", colSpan: 4 },
    { key: "price", align: "center", colSpan: 3, type: "price" },
    { key: "qty", align: "center", colSpan: 3 },
    { key: "total", align: "center", colSpan: 3, type: "price" },
    // { key: "userid", align: "center", colSpan: 3 },
    // { key: "from", align: "center", colSpan: 3 },
    // { key: "status", align: "center", colSpan: 3 },
  ];

  const nominal = detailPembelian?.totalPembelianTransaksi
    ? formatRupiah(detailPembelian?.totalPembelianTransaksi)
    : "";

  const listOfStatus = [
    "success",
    "processing",
    "expired",
    "waiting",
    "cancel",
    "failed",
  ];
  let dataSelectStatus = [];
  listOfStatus.map((item, index) => {
    dataSelectStatus.push({
      text: item === "waiting" ? "pending" : item,
      value: item,
    });
  });

  // let chek = detailPembelian.status == "sprocessing" ? "oke" : "tidak";
  // console.log(chek)

  // const [DataStatus, setDataStatus] = useState({
  //   status: detailPembelian?.status,
  // });

  // console.log(dataSelectStatus)

  const history = useHistory();
  const a = true;
  return a ? (
    <div className="m-sm-30">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <h1 className="mb-20px header-detail">Riwayat Pembelian </h1>
        <div className="back-button">
          <Button onClick={() => history.goBack()}>Back</Button>
        </div>
      </div>
      <div className="mb-20px">
        <SimpleCard loading={false} currency="" saldo="">
          <div className="simple-card-content">
            <Fragment>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <h5>Nama Pengguna</h5>
                  <div>{detailPembelian?.name}</div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Email Pengguna</h5>
                  <div>{detailPembelian?.email}</div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Total Pembelian</h5>
                  <div>{nominal}</div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Metode Pembayaran</h5>
                  <div>{detailPembelian?.payment_method}</div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Referensi</h5>
                  <div>{detailPembelian?.no_reference || "-"}</div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>No.Transaksi</h5>
                  <div>{detailPembelian?.transaction_code} </div>
                </Grid>

                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Waktu Transaksi</h5>
                  <div>{detailPembelian?.tanggal} </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <h5>Status</h5>
                  {/* <div
                    style={
                      detailPembelian?.status == "processing" ? { color: "#1253FA" }
                      : detailPembelian?.status == "waiting" || detailPembelian?.status == "pending"  ? { color: "#DF8838" }
                      : detailPembelian?.status == "success" ? { color: "#51AF77" }
                      : { color: "#0A0A0A" }
                    }
                  >
                    {detailPembelian?.status}
                  </div> */}
                  <FormControl
                    size="small"
                    variant="outlined"
                    className="form-control d-inline"
                    required={true}
                    InputProps={{
                      style: {
                        borderRadius: 5,
                        minHeight: 46,
                      },
                    }}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          size="medium"
                          style={{ fontWeight: 700 }}
                        />
                      )}
                      onChange={handleChange}
                      value={selectedOption}
                      size="small"
                      className="bg-white"
                      style={{
                        paddingRight: "2px",
                        width: "150px",
                      }}
                    >
                      {dataSelectStatus.map((data, index) => (
                        <MenuItem
                          key={index}
                          value={data.value}
                          text={data.text}
                          className={`"text-14 bg-white"`}
                          style={{
                            transform: `scaleY(${1 === "1.25" ? "0.92" : "1"})`,
                          }}
                        >
                          {data.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="d-inline back-button">
                    <Button
                      onClick={handleSubmitUpdateStatus}
                      className="d-inline ml-3"
                    >
                      Update
                    </Button>
                  </div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Tipe Pembelian</h5>
                  <div>
                    {detailPembelian?.type == "order_voucher"
                      ? "Voucher"
                      : "Produk Item"}{" "}
                  </div>
                </Grid>
                <Grid item sm={6} xs={12} className="mb-20px">
                  <h5>Respon Provider</h5>
                  <div>
                    {detailPembelian?.game_transaction_message}
                    {detailPembelian?.from == "lapakgaming" &&
                    detailPembelian?.status == "failed" ? (
                      <Button
                        color="primary"
                        className="ml-3"
                        onClick={handleResendLapakGaming}
                      >
                        Resend
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
                {detailPembelian?.from == "lapakgaming" ? (
                  <>
                    <Grid item sm={6} xs={12}></Grid>
                    <Grid item sm={6} xs={12} className="mb-20px">
                      <h5>TID</h5>
                      <div>{detailPembelian?.game_transaction_number} </div>
                    </Grid>
                    <Grid item sm={6} xs={12}></Grid>
                    <Grid item sm={6} xs={12}>
                      <h5>Status Provider</h5>
                      <div>{detailPembelian?.lg_messages || '-'}</div>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
              <Grid>
                {detailPembelian?.type === "order_voucher"
                  ? detailPembelian?.status === "success" && (
                      <div className="resend-button">
                        <Button onClick={handleResend}>
                          Resend Kode Voucher
                        </Button>
                      </div>
                    )
                  : detailPembelian?.status === "processing" && (
                      <div className="resend-button">
                        <Button onClick={handleResend}>Resend item</Button>
                      </div>
                    )}
                <ModalResendEmail
                  open={showEmailModal}
                  handleClose={closeEmailModal}
                  data={data}
                />
              </Grid>
            </Fragment>
          </div>
        </SimpleCard>
      </div>
      <div className="mb-20px">
        <SimpleCard loading={false} currency="" saldo="">
          <div className="py-4 px-8">
            <div className="mx-8 mb-8 bg-white">
              <Grid container spacing={5} alignItems="center">
                <div className="flex items-start mt-10 text-left">
                  <h3 className="fw-500 text-18 text-black font-bold">
                    Produk Dibeli
                  </h3>
                </div>
                <TableDetailListPembelian
                  tableHeadItems={tableHeadItems}
                  customColumns={tableBodyItems}
                  data={detailPembelian.product_list}
                ></TableDetailListPembelian>
              </Grid>
            </div>
          </div>
        </SimpleCard>
      </div>
    </div>
  ) : (
    <div>wait</div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailPembelian: state.transaction.detailPembelian,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDetailPembelian: (id) => dispatch(getDetailPembelian(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailPembelian);
