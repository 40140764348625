import { useEffect, useState } from "react";
import React from "react";
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Checkbox,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Aksieye from "./../../assets/components/icons/Aksieye.svg";
import { CheckBox } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  getRiwayatPembelian,
  updateBulkStatusTransaction,
} from "../../redux/actions/Transaction/TransactionActions";
import Swal from "sweetalert2";
import { connect } from "react-redux";

const TableCustomTransaction = ({
  dataRiwayatPembelian,
  totalRiwayatPembelian,
  loadingRiwayatPembelian,
  getRiwayatPembelian,
  tableHeadItems,
  customColumns,
  detailLink = "/transaction/payment/detail",
  id = "transaction_code",
  checklist = true,
  search = "",
  searchTgl = "",
  handleDeleteSearch,
  handleDeleteTgl,
}) => {
  // ==== START CHECKBOX ====
  const [state, setState] = useState({
    page: 0,
    data_checked: [],
    status: "",
    allChecked: false,
  });

  const [offsetPembelian, setOffsetPembelian] = useState(0);
  const [limitPembelian, setLimitPembelian] = useState(20);
  const [isFirstLoad, setFirstLoad] = useState(true);

  const getDataSearch = () => {
    var dateFormat = searchTgl;
    if (dateFormat) {
      dateFormat = dateFormat.toString().slice(0, 11);
      dateFormat = `?tanggal=${dateFormat}`;
    }

    let params = search === "" ? dateFormat : `?search=${search}&${dateFormat}`;

    getRiwayatPembelian(params);
  };

  const getDataLimitOffset = () => {
    let paginate = `limit=${limitPembelian}&offset=${offsetPembelian}`;

    getRiwayatPembelian(`?${paginate}`);
  };

  const handleCheckboxChange = (event) => {
    // checked or not
    const checked = event.target.checked;
    if (checked === true) {
      var start = state.page;
      var end = (state.page + 1) * limitPembelian;
      if (state.page > 0) {
        start = limitPembelian + 1;
      }
      console.log(start, end);
      setState((prevState) => ({
        ...prevState,
        data_checked: dataRiwayatPembelian
          .slice(start, end)
          .map((item) => item.transaction_code),
        allChecked: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data_checked: [],
        allChecked: false,
      }));
    }
  };

  const handleCheckboxItemChange = (event) => {
    console.log(event.target);
    // checked or not
    const checked = event.target.checked;
    // get transaction_code
    const transaction_code = event.target.name;
    // console.log("target: ", target);
    if (checked === true) {
      setState((prevState) => ({
        ...prevState,
        data_checked: [...prevState.data_checked, transaction_code],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data_checked: prevState.data_checked.filter(
          (item) => item !== transaction_code
        ),
      }));
    }
    console.log("transaction_code: ", transaction_code);
  };

  // useEffect(() => {
  //   console.log("state.data_checked: ", state.data_checked);
  // }, [state.data_checked]);

  // ==== END CHECKBOX ====

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
    setState((prevState) => ({
      ...prevState,
      data_checked: [],
      allChecked: false,
    }));
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);

    let offset = newPage * limitPembelian;
    setOffsetPembelian(offset);
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setLimitPembelian(event.target.value);

    setState({
      ...state,
      data_checked: [],
      allChecked: false,
    });
  };
  const tableHead = tableHeadItems?.map((item, index) => (
    <TableCell key={index} align={item.align} colSpan={item.colSpan}>
      <div className="table-head-font">
        {" "}
        {item.name == "CL" ? (
          <Checkbox
            color="primary"
            onChange={handleCheckboxChange}
            checked={state.allChecked}
          />
        ) : (
          item.name
        )}
      </div>
    </TableCell>
  ));

  const handleChange = ({ target: { value, name } }) => {
    setState((pref) => ({
      ...pref,
      status: value,
    }));
  };

  const updateBulkStatus = () => {
    const params = {
      status: state.status,
      data: state.data_checked,
    };
    updateBulkStatusTransaction(params)
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          text: "Data berhasil diupdate",
          timer: 2000,
          icon: "success",
        });
        setState((prevState) => ({
          ...prevState,
          data_checked: [],
          allChecked: false,
        }));
        getDataLimitOffset();
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire({
          title: "gagal",
          text: "Data Gagal Diupdate",
          timer: 2000,
          icon: "error",
        });
      });
  };

  useEffect(() => {
    getDataLimitOffset();
  }, [limitPembelian, offsetPembelian]);

  useEffect(() => {
    if (!isFirstLoad) {
      getDataSearch();
    }

    setFirstLoad(false);
  }, [search, searchTgl]);

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide overflow-hidden">
      {search && (
        <Chip
          label={`Keyword : ${search}`}
          variant="outlined"
          onDelete={handleDeleteSearch}
          className="mr-3"
        />
      )}
      {searchTgl && (
        <Chip
          label={`Tanggal : ${searchTgl}`}
          variant="outlined"
          onDelete={handleDeleteTgl}
          className="mr-3"
        />
      )}

      <Table
        style={{
          borderTop: "1px #e6e5e5 solid",
          marginTop: "20px",
        }}
      >
        <TableHead>
          <TableRow>{tableHead}</TableRow>
        </TableHead>

        {!loadingRiwayatPembelian ? (
          <TableBody>
            {dataRiwayatPembelian?.length > 0 ? (
              dataRiwayatPembelian.map((item, index) => (
                <TableRow
                  hover
                  key={index}
                  style={{
                    borderTop: "1px #e6e5e5 solid",
                    marginTop: "20px",
                  }}
                >
                  {checklist && (
                    <>
                      <TableCell
                        className="text-14 pl-2 "
                        align="center"
                        style={{ color: "#0A0A0A" }}
                        colSpan={1}
                      >
                        <Checkbox
                          color="primary"
                          onChange={handleCheckboxItemChange}
                          name={item.transaction_code}
                          checked={
                            state.data_checked.filter(
                              (dt) => dt === item.transaction_code
                            ).length > 0
                              ? true
                              : false
                          }
                        />
                      </TableCell>
                    </>
                  )}
                  {customColumns?.map((column, index) => (
                    <TableCell
                      key={index}
                      className="text-14 pl-2"
                      style={
                        column.type === "topup" && item[column.key] > 0
                          ? { color: "#51AF77" }
                          : column.type === "topup" && item[column.key] < 0
                          ? { color: "#D55454" }
                          : column.type === "status" &&
                            item[column.key] == "processing"
                          ? { color: "#1253FA" }
                          : (column.type === "status" &&
                              item[column.key] == "waiting") ||
                            (column.type === "status" &&
                              item[column.key] == "pending")
                          ? { color: "#DF8838" }
                          : column.type === "status" &&
                            item[column.key] == "success"
                          ? { color: "#51AF77" }
                          : { color: "#0A0A0A" }
                      }
                      colSpan={column.colSpan}
                      align={column.align}
                    >
                      {column.type === "price" ||
                      (column.type === "topup" && item[column.key])
                        ? `Rp ${item[column.key]
                            .toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}`
                        : column.type === "date" && item[column.key]
                        ? new Date(item[column.key]).toLocaleDateString("en-US")
                        : column.type !== "pembelian"
                        ? item[column.key]
                        : null}

                      {/* Nomor Transaksi */}
                      <Link to={`${detailLink}/${item[id]}`}>
                        {column.type === "pembelian" && item[column.key]}
                      </Link>
                    </TableCell>
                  ))}
                  <TableCell align="center" colSpan={3} className="pl-2">
                    <Link to={`${detailLink}/${item[id]}`}>
                      <img src={Aksieye} alt="eye" />
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <>
                <TableRow
                  hover
                  style={{
                    borderTop: "1px #e6e5e5 solid",
                    marginTop: "20px",
                  }}
                >
                  <TableCell colSpan={18} align="center">
                    Data kosong
                  </TableCell>
                </TableRow>
              </>
            )}
            {/* <RenderTable
            data={dataRiwayatPembelian}
            state={state}
            setState={setState}
            getData={getData}
            search={''}
            customColumns={customColumns}
            3={3}
            detailLink={detailLink}
            id={id}
            checklist={checklist}
            checkedData={state.data_checked}
          /> */}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow
              hover
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableCell colSpan={18} align="center">
                <CircularProgress color="primary" size={35} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>

      {checklist ? (
        <div className="d-flex justify-content-between align-content-center align-items-center">
          <div className="d-flex align-content-center align-items-center">
            <div className="d-flex align-content-center align-items-center">
              <CheckBox className="ml-5" htmlColor="#BABEC1" />
              <p className="p-0 m-0" style={{ color: "#BABEC1" }}>
                {state.data_checked.length}/{dataRiwayatPembelian.length} Data
              </p>
            </div>
            {state.data_checked.length > 0 ? (
              <div className="d-flex align-content-center align-items-center ml-5">
                <Select
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      size="medium"
                      style={{ fontWeight: 700 }}
                    />
                  )}
                  onChange={handleChange}
                  value={state.status}
                  style={{ borderRadius: 5, maxHeight: 35 }}
                  size={"sm"}
                  className="bg-white p-0"
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem key={0} value={""} text={"Status"}>
                    Status
                  </MenuItem>
                  <MenuItem key={1} value={"success"} text={"Success"}>
                    Success
                  </MenuItem>
                  <MenuItem key={2} value={"processing"} text={"Processing"}>
                    Processing
                  </MenuItem>
                  <MenuItem key={3} value={"waiting"} text={"Waiting"}>
                    Waiting
                  </MenuItem>
                  <MenuItem key={4} value={"pending"} text={"Pending"}>
                    Pending
                  </MenuItem>
                  <MenuItem key={5} value={"cancel"} text={"Cancel"}>
                    Cancel
                  </MenuItem>
                </Select>
                {state.status !== "" ? (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className="text-white w-full text-capitalize pc-2 ml-3"
                    disableElevation
                    style={{
                      borderRadius: 5,
                    }}
                    onClick={updateBulkStatus}
                  >
                    Save
                  </Button>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <TablePagination
            className="px-16"
            rowsPerPageOptions={[20, 40, 100]}
            component="div"
            count={totalRiwayatPembelian}
            rowsPerPage={limitPembelian}
            labelRowsPerPage={"From"}
            page={state.page}
            backIconButtonProps={{
              "aria-label": "Previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next page",
            }}
            backIconButtonText="Previous page"
            nextIconButtonText="Next page"
            onChangePage={handleChangePage}
            onChangeRowsPerPage={setRowsPerPage}
          />
        </div>
      ) : (
        <TablePagination
          className="px-16"
          rowsPerPageOptions={[20, 40, 100]}
          component="div"
          count={totalRiwayatPembelian}
          rowsPerPage={limitPembelian}
          labelRowsPerPage={"From"}
          page={state.page}
          backIconButtonProps={{
            "aria-label": "Previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next page",
          }}
          backIconButtonText="Previous page"
          nextIconButtonText="Next page"
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPage}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataRiwayatPembelian: state.transaction.dataRiwayatPembelian,
    totalRiwayatPembelian: state.transaction.totalRiwayatPembelian,
    loadingRiwayatPembelian: state.transaction.loadingRiwayatPembelian,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRiwayatPembelian: (params) => dispatch(getRiwayatPembelian(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableCustomTransaction);
